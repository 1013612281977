/* eslint-disable react/jsx-newline */
import React from "react";
import Head from "next/head";

export type PageMetaProps = {
  title: string;
  description?: string;
  skipSuffix?: boolean;
};

const constructPageTitle = (title: string, skipSuffix = false) => {
  const siteName = "Eleo";

  if (skipSuffix) {
    return title;
  }

  if (!title) {
    return siteName;
  }

  return `${title} | ${siteName}`;
};

export const PageMeta = ({ title, description, skipSuffix }: PageMetaProps) => {
  return (
    <Head>
      <title>{constructPageTitle(title, skipSuffix)}</title>

      {description && <meta name="description" content={description} />}
    </Head>
  );
};

/**
 * Next 13 compatible version of PageMeta
 * @param title
 * @constructor
 */
export const PageTitle = ({ title, skipSuffix }: PageMetaProps) => {
  return (
    <>
      <title>{constructPageTitle(title, skipSuffix)}</title>
    </>
  );
};
