import { styled } from "../../styles";
import React, { Fragment, PropsWithChildren } from "react";
import { Body, Heading } from "../Typography/Typography";
import Button from "../Button/Button";
import Link from "next/link";
import type * as Stitches from "@stitches/react";

const DashboardCardContainer = styled("div", {
  backgroundColor: "$white",
  borderRadius: "24px",
  boxShadow: "$darkLightGrayOpacity20 0px 2px 8px 0px",
  height: "auto",
  padding: "$4",

  "> form": {
    marginTop: "$2",
  },

  "@print": {
    boxShadow: "none",
    minHeight: "auto",
    padding: "0",
  },

  "@bp2": {
    minHeight: "360px",
  },

  variants: {
    borderless: {
      true: {
        boxShadow: "none",
        border: "none",
        padding: "$2",
      },
    },
  },
});

const DashboardCardTitle = styled(Heading, {
  margin: "0",
});

export interface DashboardCardProps {
  borderless?: boolean;
  styleAttributes?: Stitches.CSS;
  title?: string;
}

export const DashboardCard = ({
  borderless = false,
  children,
  styleAttributes,
  title,
}: PropsWithChildren<DashboardCardProps>) => {
  return (
    <DashboardCardContainer
      borderless={borderless}
      css={{ ...(styleAttributes || {}) }}
    >
      {title && <DashboardCardTitle type={"h6"}>{title}</DashboardCardTitle>}

      {children}
    </DashboardCardContainer>
  );
};

export interface HeaderDashboardCardProps {
  cardBackground?: string;
  cta: {
    link?: string;
    onClick?: () => void;
    text: string;
  } | null;
  description: string;
  image: string;
  title: string;
}

const HeaderCardContent = styled("div", {
  alignItems: "center",
  display: "grid",
  height: "100%",
  rowGap: "$2",
  flexDirection: "column",
  justifyContent: "center",
  textAlign: "center",
  width: "100%",

  "& > *": {
    color: "$white",
  },

  "@bp1a": {
    alignItems: "initial",
    justifyContent: "initial",
    textAlign: "left",
    width: "200px",
  },

  [`${Body}`]: {
    fontWeight: "400",
  },
});

const HeaderImageContainer = styled("div", {
  alignItems: "center",
  display: "none",
  justifyContent: "center",
  height: "100%",

  "@bp1a": {
    display: "flex",
    marginLeft: "auto",
  },

  img: {
    maxHeight: "210px",
    width: "auto",
  },
});

export const HeaderDashboardCard = ({
  cardBackground,
  image: headerImage,
  title,
  cta,
  description,
}: HeaderDashboardCardProps) => {
  const ctaLink = cta?.link;
  const ctaText = cta?.text;

  const CTAComp = ctaLink ? Link : Fragment;
  const ctaProps: any = ctaLink ? { href: ctaLink || "" } : {};

  return (
    <DashboardCard
      styleAttributes={{
        backgroundColor: cardBackground || "$primary",
        color: "$white",
        display: "flex",

        "&:hover": {},
      }}
    >
      <HeaderCardContent>
        <Heading
          type={"h5"}
          css={{
            color: "$white",
            margin: "0",
          }}
        >
          {title}
        </Heading>

        <Body>{description}</Body>

        {cta && (
          <CTAComp {...ctaProps}>
            <Button onClick={cta?.onClick} design={"white"}>
              {ctaText}
            </Button>
          </CTAComp>
        )}
      </HeaderCardContent>

      <HeaderImageContainer>
        <img src={headerImage} alt={title} />
      </HeaderImageContainer>
    </DashboardCard>
  );
};

export const CardLink = styled(Link, {
  color: "$darkGoldenrod",
  display: "flex",
  fontSize: "13px",
  fontWeight: "600",
  justifyContent: "center",
  padding: "0 $2 $2 $2",
  textAlign: "center",
  width: "100%",
});
