import React, { PropsWithChildren } from "react";
import * as LabelPrimitive from "@radix-ui/react-label";
import { styled } from "../../styles";

const BaseLabel = styled(LabelPrimitive.Root, {
  color: "$darkShade5",
  fontFamily: "$body",
  fontSize: "12px",
  fontWeight: 500,
  lineHeight: "$2",
});

export interface LabelProps {
  htmlFor?: string;
  as?: string;
  css?: any;
}

export const Label = ({
  children,
  htmlFor,
  as,
  ...rest
}: PropsWithChildren<LabelProps>) => (
  <BaseLabel htmlFor={htmlFor} as={(as || "label") as any} {...rest}>
    {children}
  </BaseLabel>
);
