import React, { Fragment } from "react";
import { styled } from "../../styles";
import { GridContainer } from "../Layout/GridContainer";
import { Caption } from "../Typography/Typography";
import { GridItem } from "../Layout/GridItem";

const Wrapper = styled("div", {
  backgroundColor: "$white",
  borderRadius: "24px",

  "@bp1a": {
    // padding: "32px",
  },
});

export const LIST_GRID_TEMPLATE_COLUMNS = "50px 1fr 150px 150px";

const ListGrid = styled(GridContainer, {
  columnGap: "$2",
  gridTemplateColumns: "1fr",
});

const ListHeader = styled(ListGrid, {
  alignItems: "center",
  borderBottom: "1px solid #E4E4E4",
  display: "none",
  padding: "16px 0",

  "@bp3a": {
    display: "grid",
  },
});

const ListHeaderText = styled(Caption, {
  color: "$textDeActive",
});

export type GenericListHeader = {
  id: string;
  text: string;
  renderHeader?: () => JSX.Element;
  orderingKey?: string;
};

export interface GenericListProps<T> {
  headers: GenericListHeader[];
  items: T[];
  renderItem: (item: T) => JSX.Element;
  customGridTemplateColumns?: string;
  onOrdering?: (orderingKey: string) => void;
}

export type BaseItem = {
  id: string | number;
};

export const GenericList = <T extends BaseItem>({
  items,
  headers,
  renderItem,
  customGridTemplateColumns,
  onOrdering,
}: GenericListProps<T>) => {
  const currentGrid = {
    "@bp3a": {
      gridTemplateColumns:
        customGridTemplateColumns || LIST_GRID_TEMPLATE_COLUMNS,
    },
  };

  const handleHeaderClick = (orderingKey: string | undefined) => {
    if (!orderingKey || !onOrdering) return;

    onOrdering(orderingKey);
  };

  return (
    <Wrapper>
      <ListHeader
        css={{
          ...currentGrid,
        }}
      >
        {headers.map(({ id, renderHeader, text: headerText, orderingKey }) => {
          const HeaderItemContainer = "div";

          return (
            <HeaderItemContainer
              key={id}
              onClick={() => handleHeaderClick(orderingKey)}
            >
              {typeof renderHeader === "function" ? (
                <Fragment>{renderHeader()}</Fragment>
              ) : (
                <ListHeaderText key={id}>{headerText}</ListHeaderText>
              )}
            </HeaderItemContainer>
          );
        })}
      </ListHeader>

      {Array.isArray(items) &&
        items.map((item) => (
          <GridItem
            key={item.id}
            css={{
              "&:last-child > div": {
                borderBottom: "none",
              },
            }}
          >
            {renderItem(item)}
          </GridItem>
        ))}
    </Wrapper>
  );
};
