import { ComponentProps, VariantProps } from "@stitches/react";
import { forwardRef, Fragment } from "react";
import { styled } from "../../styles";
import { PhoneInputProps } from "../PhoneInput";
import { baseInputStyles } from "./Input.styles";
import { RichTextEditorProps } from "../RichTextEditor/RichTextEditor";
import Button from "../Button/Button";
import { Controller } from "react-hook-form";
import { UploadInputProps } from "../FileUploader/UploadInput";
import dynamic from "next/dynamic";
import { DatePickerProps } from "../Datepicker/DatePicker";

const UploadInput = dynamic(
  () => import("../FileUploader/UploadInput").then((mod) => mod.UploadInput),
  {
    ssr: false,
  },
);

const DatePicker = dynamic(() =>
  import("../Datepicker/DatePicker").then((mod) => mod.DatePicker),
);

const RichTextEditor = dynamic(() =>
  import("../RichTextEditor/RichTextEditor").then((mod) => mod.RichTextEditor),
);

const PhoneInput = dynamic(() =>
  import("../PhoneInput").then((mod) => mod.PhoneInput),
);

const handleNumberInputScroll = (e: React.WheelEvent<HTMLInputElement>) => {
  //   Prevent changing the value of the input when scrolling
  e.stopPropagation();

  (e.target as HTMLInputElement)?.blur();
};

export const BaseInput = styled("input", {
  ...baseInputStyles,

  '&[type="checkbox"]': {
    accentColor: "$primary",
    backgroundColor: "$white",
    border: "2px solid $darkShade4",
    borderRadius: "4px",
    cursor: "pointer",
    height: "20px",
    width: "20px",

    "&:focus": {
      outline: "none",
    },
  },

  "&:read-only:not([type='checkbox'])": {
    cursor: "not-allowed",
  },

  '&[type="textarea"]': {
    height: "300px",
  },
});

export type InputProps = ComponentProps<typeof BaseInput> &
  VariantProps<typeof BaseInput> &
  React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > & {
    control?: any;
    as?: string | undefined;
    showSteps?: boolean;
    type?: string;
  };

export type NumberInputProps = InputProps & {
  showSteps?: boolean;
  allowNegativeSteps?: boolean;
};

const NumberInputContainer = styled("div", {
  backgroundColor: baseInputStyles.backgroundColor,
  display: "flex",

  [`${BaseInput}`]: {
    backgroundColor: "transparent",
    flex: 1,
  },
});

const NumberInputButton = styled(Button, {
  borderRadius: "0",
  fontSize: "20px",
  height: baseInputStyles.height,
  margin: "0",
  padding: "$1 $2",
  width: "45px",
});

export const NumberInput = (props: NumberInputProps) => {
  const { control, name, showSteps, allowNegativeSteps, ...rest } = props;

  if (!control) {
    return <BaseInput name={name || ""} {...rest} />;
  }

  return (
    <Controller
      control={control}
      name={name || ""}
      render={({ field: { onChange, ref, value } }) => {
        const adjustInputValue = (val: number) => {
          const currentValue = parseFloat(value || "0");

          let nextValue = currentValue + val;

          if (!allowNegativeSteps && nextValue < 0) {
            nextValue = 0;
          }

          if (nextValue < 0 && !allowNegativeSteps) {
            return;
          }

          onChange(nextValue);
        };

        return (
          <NumberInputContainer>
            <BaseInput
              {...rest}
              ref={ref}
              value={value}
              type="number"
              onChange={(e) => onChange(e.target.value)}
              onWheel={handleNumberInputScroll}
            />

            {showSteps && (
              <Fragment>
                <NumberInputButton
                  onClick={() => {
                    adjustInputValue(-1);
                  }}
                  type={"button"}
                  design={"textOnly"}
                >
                  -
                </NumberInputButton>

                <NumberInputButton
                  onClick={() => {
                    adjustInputValue(1);
                  }}
                  type={"button"}
                  design={"textOnly"}
                >
                  +
                </NumberInputButton>
              </Fragment>
            )}
          </NumberInputContainer>
        );
      }}
    />
  );
};

NumberInput.displayName = "NumberInput";

export const Input = forwardRef<any, InputProps>((props, ref) => {
  const newProps = { ...props };

  if (props.type === "price") {
    newProps.min = "0";
    newProps.type = "number";
    newProps.step = ".01";
  }

  if (props.type === "number" && !props.step) {
    newProps.step = "0.01";
  }

  if (props.showSteps) {
    return <NumberInput {...newProps} />;
  }

  if (props.type === "date") {
    return <DatePicker {...(newProps as DatePickerProps)} />;
  }

  if (props.type === "time") {
    return (
      <DatePicker
        showTimeSelect
        showTimeSelectOnly
        timeIntervals={15}
        timeCaption="Time"
        dateFormat="HH:mm"
        timeFormat="HH:mm"
        {...(newProps as DatePickerProps)}
      />
    );
  }

  if (props.type === "tel") {
    return <PhoneInput {...(newProps as PhoneInputProps)} />;
  }

  if (props.type === "richtext") {
    return <RichTextEditor {...(newProps as RichTextEditorProps)} />;
  }

  if (props.type === "file") {
    return <UploadInput {...(newProps as UploadInputProps)} />;
  }

  return (
    <BaseInput
      ref={ref}
      as={newProps.type === "textarea" && "textarea"}
      onWheel={props.type === "number" ? handleNumberInputScroll : undefined}
      {...newProps}
    />
  );
});

Input.displayName = "Input";

export default Input;
