import Input, { InputProps } from "./Input";
import Button from "../Button/Button";
import { styled } from "../../styles";
import React, { forwardRef } from "react";

export type SearchInputProps = InputProps & {
  defaultValue?: string;
};

const Container = styled("div", {
  alignItems: "center",
  backgroundColor: "$darkSlateGrayOpacity30",
  // backgroundColor: "red",
  borderRadius: "16px",
  display: "flex",

  padding: "4px",
  maxWidth: "700px",
  width: "100%",

  "@bp1a": {
    padding: "4px 4px 4px $2",
  },

  input: {
    backgroundColor: "transparent",

    "&:focus": {
      outline: "none",
    },
  },

  button: {
    width: "148px",
  },
});

const SearchIcon = styled("img", {
  display: "none",
  height: "24px",
  width: "24px",

  "@bp1a": {
    display: "block",
  },
});

const SearchInputButton = styled(Button, {
  padding: "$2 0",

  [`& ${SearchIcon}`]: {
    display: "block",
    filter: "invert(1)",

    "@bp1a": {
      display: "none",
    },
  },

  span: {
    display: "none",

    "@bp1a": {
      display: "inline-block",
    },
  },
});

export const SearchInput = forwardRef<HTMLInputElement, SearchInputProps>(
  (props, ref) => {
    return (
      <Container>
        <SearchIcon src="/images/icons/search.svg" alt="search" />

        <Input
          type="search"
          placeholder="Search"
          ref={ref}
          required
          {...props}
        />

        <SearchInputButton type={"submit"} mode="primary">
          <SearchIcon src="/images/icons/search.svg" alt="search" />

          <span>Search</span>
        </SearchInputButton>
      </Container>
    );
  },
);

SearchInput.displayName = "SearchInput";
