import { ModalConfigProps, useModal } from "./Modal";
import { GridContainer } from "../Layout/GridContainer";
import { GridItem } from "../Layout/GridItem";
import React, { PropsWithChildren } from "react";
import { ModalHeader } from "./Modal.styles";
import ReactModal from "react-modal";

export type BaseModalProps = PropsWithChildren<{
  config: ModalConfigProps | null;
  isOpen: boolean;
  onDismiss?: () => void;
}>;

export const BaseModal = ({
  children,
  config,
  isOpen,
  onDismiss,
}: BaseModalProps) => {
  const { closeModal } = useModal();

  ReactModal.setAppElement("#eleoRoot");

  if (!config) {
    return null;
  }

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={() => {
        closeModal();
        onDismiss?.();
      }}
      shouldCloseOnOverlayClick
      shouldCloseOnEsc
      shouldReturnFocusAfterClose
      className={"eleoModal"}
      overlayClassName={"eleoModalOverlay"}
    >
      <GridContainer>
        <GridItem desktop={12}>
          <ModalHeader>{config.title}</ModalHeader>
        </GridItem>

        <GridItem desktop={12}>{config.body || children}</GridItem>
      </GridContainer>
    </ReactModal>
  );
};
