import { GridContainer } from "../Layout/GridContainer";
import { GridItem } from "../Layout/GridItem";
import { FormGroup } from "../FormGroup/FormGroup";
import { useForm } from "react-hook-form";
import { Option, SelectProps } from "../Select/Select";
import Button from "../Button/Button";
import { InputProps } from "../Input/Input";
import { PhoneInputProps } from "../PhoneInput";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { DatePickerProps } from "../Datepicker/DatePicker";
import useDeviceDetect, { DeviceType } from "../../hooks/useDeviceDetect";

export type Filter = {
  defaultValue?: string;
  id: string;
  label: string;
  options?: Option[];
  type: "select" | "text" | "tel" | "date" | "number" | "email";
  inputProps: SelectProps | InputProps | PhoneInputProps | DatePickerProps;
};

export type FilterResponseShape = {
  [key: string]: string;
};

export interface ListFilterProps {
  defaultValues?: FilterResponseShape;
  filters: Filter[];
  onFilter: (queryString: object) => void;
  staticFilter?: FilterResponseShape; // These are filter values passed such that when the reset
  // is triggered, they are not raised
  withRouting?: boolean; // If the filter should update the URL with the query string; enabled by default
}

const defaultFilterItemsShownAtThreshold: {
  [key in DeviceType]: number;
} = {
  [DeviceType.Mobile]: 2,
  [DeviceType.Tablet]: 3,
  [DeviceType.Desktop]: 5,
};

export const ListFilter = ({
  defaultValues,
  filters,
  onFilter,
  staticFilter = undefined,
  withRouting = true,
}: ListFilterProps) => {
  const router = useRouter();
  const { handleSubmit, formState, control, register, reset, setValue } =
    useForm();
  const [defaultValuesHydrated, setDefaultValuesHydrated] = useState(false);

  const currentDevice = useDeviceDetect();

  const FILTER_COLLAPSE_THRESHOLD =
    typeof window !== "undefined"
      ? defaultFilterItemsShownAtThreshold[currentDevice]
      : 2;
  const collapsedByDefault = filters.length > FILTER_COLLAPSE_THRESHOLD;
  const canBeCollapsed = filters.length > FILTER_COLLAPSE_THRESHOLD;

  const [collapsed, setCollapsed] = useState(collapsedByDefault);
  const visibleFilters = collapsed
    ? filters.slice(0, FILTER_COLLAPSE_THRESHOLD)
    : filters;

  const canBeReset = formState.isDirty || Object.values(filters).length > 0;

  const onSubmit = (data: FilterResponseShape) => {
    const response: FilterResponseShape = {};
    Object.keys(data).forEach((key) => {
      const val = data[key];
      if (val) {
        response[key] = val;
      }
    });

    handleFiltering(response);
  };

  const handleFiltering = (data: FilterResponseShape) => {
    onFilter(data);

    if (withRouting) {
      const existingItems = router.query;

      // Check if the new filter parameters match the existing ones
      const areFiltersSame =
        Object.keys(data).every((key) => data[key] === existingItems[key]) &&
        Object.keys(existingItems).every(
          (key) => existingItems[key] === data[key],
        );

      // If they are not the same, update the route
      if (!areFiltersSame) {
        if (Object.keys(data).length === 0) {
          router.push(router.pathname, undefined, { shallow: true });
        } else {
          router.push(
            {
              pathname: router.pathname,
              query: data,
            },
            undefined,
            { shallow: true },
          );
        }
      }
    }
  };

  useEffect(() => {
    if (
      !defaultValuesHydrated &&
      defaultValues &&
      Object.keys(defaultValues).length > 0
    ) {
      Object.keys(defaultValues).map((key) => {
        setValue(key, defaultValues[key], { shouldDirty: true });
      });
      setDefaultValuesHydrated(true);
      handleFiltering(defaultValues);
    }
  }, [defaultValues, defaultValuesHydrated]);

  if (filters.length === 0) {
    return null;
  }

  const FilterFormGroup = FormGroup as any;

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <GridContainer
        css={{
          alignItems: "end",
        }}
      >
        {visibleFilters.map((filter) => {
          let inputProps = filter.inputProps || {};
          if (filter.type === "select") {
            inputProps = inputProps as SelectProps;
          } else {
            inputProps = inputProps as InputProps;
          }

          return (
            <GridItem tablet={6} xxl={2} desktop={3} key={filter.id}>
              <FilterFormGroup
                id={filter.id}
                label={filter.label}
                type={filter.type}
                inputProps={{
                  control,
                  ...inputProps,
                  ...register(filter.id),
                }}
              />
            </GridItem>
          );
        })}

        <GridItem
          tablet={3}
          desktop={1}
          css={{
            alignItems: "center",
            display: "flex",
            height: "100%",
            paddingTop: "$1",
          }}
        >
          <Button type="submit" inline size="small">
            Filter
          </Button>

          {canBeReset && (
            <Button
              type="reset"
              size="small"
              inline
              onClick={() => {
                reset(staticFilter);
                handleFiltering(staticFilter || {});
              }}
              mode={"warning"}
            >
              Reset
            </Button>
          )}

          {canBeCollapsed && (
            <Button
              type="button"
              size="small"
              inline
              onClick={() => {
                setCollapsed(!collapsed);
              }}
              design={"textOnly"}
            >
              {collapsed ? "All" : "Collapse"} Filters
            </Button>
          )}
        </GridItem>
      </GridContainer>
    </form>
  );
};
