import React, { Fragment } from "react";
import { GridContainer } from "../Layout/GridContainer";
import Input from "../Input/Input";
import { Body } from "../Typography/Typography";
import { styled } from "../../styles";
import Link from "next/link";
import {
  BaseItem,
  LIST_GRID_TEMPLATE_COLUMNS,
} from "../GenericList/GenericList";

const Container = styled(GridContainer, {
  alignItems: "center",
  borderBottom: "1px solid #E4E4E4",
  gridTemplateColumns: "1fr 1fr",
  padding: "24px 0",

  [`${Body}`]: {
    margin: 0,
  },

  "@bp3a": {
    gridTemplateColumns: LIST_GRID_TEMPLATE_COLUMNS,
  },
});

const SelectContainer = styled("div", {
  display: "none",

  "@bp1a": {
    display: "flex",
  },
});

const TitleContainer = styled("div", {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
});

const NameContainer = styled(Link, {
  display: "flex",
  gridColumn: "1/3",

  "@bp1a": {
    gridColumn: "initial",
  },
});

export interface GenericListItemProps<T> {
  item: T;
  href: string | null;
  customGridTemplateColumns?: string;
  columns: {
    id: string;
    renderColumn: (item: T) => JSX.Element;
  }[];
  renderTitle: (item: T) => JSX.Element;
  showCheckbox?: boolean;
}

export const GenericListItem = <T extends BaseItem>({
  href,
  item,
  renderTitle,
  customGridTemplateColumns,
  showCheckbox = true,
  columns,
}: GenericListItemProps<T>) => {
  const defaultGrid = showCheckbox
    ? LIST_GRID_TEMPLATE_COLUMNS
    : "1fr 150px 150px";

  const currentGrid = {
    "@bp3a": {
      gridTemplateColumns: customGridTemplateColumns || defaultGrid,
    },
  };

  return (
    <Container
      css={{
        ...currentGrid,
      }}
    >
      {showCheckbox && (
        <SelectContainer>
          <Input type="checkbox" name={item.id as string} />
        </SelectContainer>
      )}

      <NameContainer href={href as string} as={!href ? "div" : undefined}>
        <TitleContainer>{renderTitle(item)}</TitleContainer>
      </NameContainer>

      {columns.map((column) => (
        <Fragment key={column.id}>{column.renderColumn(item)}</Fragment>
      ))}
    </Container>
  );
};
