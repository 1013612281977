import React from "react";
import WindowedSelect, { createFilter } from "react-windowed-select";

import { styled } from "../../styles";
import { ComponentProps, VariantProps } from "@stitches/react";
import { Controller } from "react-hook-form";
import { BaseInputProps } from "../FormGroup/FormGroup";
import CreatableSelect from "react-select/creatable";

const baseSelectStyles = {
  fontSize: "14px",
};

const SelectContainer = styled("div", {
  fontSize: "14px",

  variants: {
    small: {
      true: {
        fontSize: "12px",
      },
    },
  },
});

const customStyles = {
  option: (provided: object, state: any) => ({
    ...provided,
    backgroundColor:
      state.isSelected || state.isFocused ? "rgba(189,197,209,.3)" : "white",
  }),
  control: (provided: object) => ({
    ...provided,
    backgroundColor: "var(--colors-darkSlateGrayOpacity30)",
    border: "none",
    borderRadius: "8px",
    height: "56px",
  }),
  menu: (provided: object) => ({
    ...provided,
    backgroundColor: "var(--colors-white)",
  }),
  singleValue: (provided: object) => ({
    ...provided,
    color: "var(--colors-textActive) !important",
  }),
  multiValue: (provided: object) => ({
    ...provided,
    backgroundColor: "var(--colors-white) !important",
  }),
  valueContainer: (provided: object) => ({
    ...provided,
    color: "var(--colors-textActive) !important",
  }),
};

export type Option = {
  value: string | number;
  label: string;
};

const sharedSelectStyles = {
  "#react-select-category_id-listbox": {
    backgroundColor: "$white !important",
  },
};

const StyledCreatableSelect = styled(CreatableSelect, {
  ...sharedSelectStyles,
});

const StyledWindowedSelect = styled(WindowedSelect, {
  ...sharedSelectStyles,
});

export type SelectProps = ComponentProps<typeof WindowedSelect> &
  VariantProps<typeof WindowedSelect> &
  BaseInputProps &
  React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLSelectElement>,
    HTMLSelectElement
  > & {
    options: Option[];
    control: any;
    isMulti?: boolean;
    isCreatable?: boolean;
    isValidNewOption?: (inputValue: string) => boolean;
    onKeyDown?: (e: React.KeyboardEvent) => void;
    isLoading?: boolean;
    handleCreate?: (val: string) => void;
    small?: boolean;
  };

export const Select = ({
  name,
  options = [],
  control,
  isMulti,
  required,
  isCreatable,
  onKeyDown,
  isValidNewOption,
  isLoading,
  placeholder,
  handleCreate,
  small,
}: SelectProps) => {
  const Comp: any = isCreatable ? StyledCreatableSelect : StyledWindowedSelect;

  return (
    <Controller
      control={control}
      rules={{ required }}
      name={name || ""}
      render={({ field: { onChange, ref, value } }) => {
        const getCurrentValue = () => {
          if (!value) {
            return "";
          }

          if (isMulti) {
            return options.filter((option: Option) => {
              return (value || []).includes(option.value);
            });
          }
          return options.find((option: Option) => option.value === value);
        };

        const handleOnChange = (val: Option | Option[]) => {
          const newValue = isMulti
            ? (val as Option[]).map((v) => v.value)
            : (val as Option).value;
          onChange(newValue);
        };

        const handleCreateOption = async (val: string) => {
          if (!handleCreate) {
            return;
          }
          await handleCreate(val);

          // const item = options.find((option: Option) => option.label === val);
          //
          // if (!item) {
          //   return;
          // }

          if (!isMulti) {
            onChange(val);
            return;
          }

          const newValue = (value || []).concat(val);
          onChange(newValue);
        };

        return (
          <SelectContainer small={small}>
            <Comp
              ref={ref}
              filterOption={createFilter({
                ignoreAccents: false,
              })}
              id={name}
              onKeyDown={onKeyDown}
              value={getCurrentValue()}
              isLoading={isLoading}
              onCreateOption={handleCreateOption}
              instanceId={name}
              isCreatable={isCreatable}
              isValidNewOption={isValidNewOption}
              theme={(theme: any) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  // primary25: "$darkSlateGrayOpacity30",
                  primary: "$darkMain",
                  primary75: "$darkMain",
                  primary50: "$darkMain",
                  primary25: "$darkMain",
                  neutral0: "$white",
                  neutral5: "$white",
                  neutral10: "$white",
                  neutral30: "$white",
                  neutral40: "$white",
                  neutral50: "$white",
                  neutral20: "$white",
                },
              })}
              styles={{
                ...baseSelectStyles,
                ...customStyles,
              }}
              isMulti={isMulti}
              onChange={(val: any) => handleOnChange(val)}
              options={options}
              placeholder={placeholder}
            />
          </SelectContainer>
        );
      }}
    />
  );
};

Select.displayName = "Select";
