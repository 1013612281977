import React from "react";
import { Caption } from "../Typography/Typography";
import { styled } from "../../styles";
import { Label } from "../Label/Label";

export type KeyValuePairProps = {
  css?: object;
  label: string;
  value: string | number | JSX.Element | null | undefined;
};

const Container = styled("div", {
  alignItems: "center",
  columnGap: "$2",
  display: "grid",
  gridColumn: "span 12",
  gridTemplateColumns: "1fr",
  rowGap: "$1",

  "&:not(:last-child)": {
    borderBottom: "1px solid rgb(228 228 228 / 80%)",
    paddingBottom: "4px",
  },

  "@bp2a": {
    gridTemplateColumns: "200px 1fr",
  },
});

const TitleContainer = styled("div", {
  [`${Label}`]: {
    fontFamily: "$headings",
    fontWeight: 500,
  },
});

const ValueContainer = styled("div", {
  [`${Caption}`]: {
    fontSize: "14px",
    fontWeight: 400,
  },
});

export const KeyValuePair = ({ css = {}, label, value }: KeyValuePairProps) => {
  if (!value) {
    return null;
  }

  return (
    <Container css={{ ...(css || {}) }}>
      <TitleContainer>
        <Label
          css={{
            color: "$darkMain",
          }}
        >
          {label}
        </Label>
      </TitleContainer>

      <ValueContainer>
        <Caption>{value}</Caption>
      </ValueContainer>
    </Container>
  );
};
