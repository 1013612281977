export const baseInputStyles = {
  backgroundColor: "$darkSlateGrayOpacity30",
  border: "none",
  borderRadius: "8px",
  color: "$textActive",
  fontFamily: "$body",
  fontSize: "16px",
  fontWeight: "600",
  height: "56px",
  outline: "2px solid transparent",
  padding: "$2",
  transition: "all .2s ease-in-out",
  width: "100%",

  "&:focus": {
    outlineColor: "$primary",
  },

  "@bp1a": {
    fontSize: "14px",
  },
};
