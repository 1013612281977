import { keyframes } from "@stitches/react";
import { styled } from "../../styles";

const loaderAnimation = keyframes({
  "0%": {
    transform: "rotate(0)",
    animationTimingFunction: "cubic-bezier(0.55, 0.055, 0.675, 0.19)",
  },
  "50%": {
    transform: "rotate(900deg)",
    animationTimingFunction: "cubic-bezier(0.215, 0.61, 0.355, 1)",
  },
  "100%": {
    transform: "rotate(1800deg)",
  },
});

export const Loader = styled("div", {
  display: "flex",
  height: "80px",
  marginLeft: "auto",
  marginRight: "auto",
  padding: "$3",
  position: "relative",
  width: "80px",

  "&:after": {
    content: " ",
    display: "block",
    borderRadius: "50%",
    width: 0,
    height: 0,
    margin: "$1",
    boxSizing: "border-box",
    border: "32px solid $primary",
    borderColor: "$primary transparent $primary transparent",
    animation: `${loaderAnimation} 1.2s infinite`,
  },
});
