import { Controller } from "react-hook-form";
import { BaseInputProps, Button } from "@eleo/components";
import { Option } from "./Select";
import { styled } from "../../styles";

export type FlatSelectProps = BaseInputProps & {
  options: Option[];
  control: any;
  name: string;
};

const Container = styled("div", {
  display: "flex",
  gap: "4px",
  flexWrap: "wrap",
});

const FlatSelect = ({ options, control, name, required }: FlatSelectProps) => {
  return (
    <Controller
      control={control}
      rules={{ required }}
      render={({ field: { onChange, value } }) => {
        const handleOnChange = (val: Option) => {
          onChange(val.value);
        };

        return (
          <Container>
            {options.map((option) => {
              const isActive = value === option.value;

              return (
                <Button
                  key={option.value}
                  type={"button"}
                  inline
                  bordered={!isActive}
                  size={"small"}
                  mode={isActive ? "primary" : undefined}
                  onClick={() => handleOnChange(option)}
                  css={{
                    marginLeft: "0",
                  }}
                >
                  {option.label}
                </Button>
              );
            })}
          </Container>
        );
      }}
      name={name || ""}
    />
  );
};

export default FlatSelect;
