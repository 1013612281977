import React, { PropsWithChildren } from "react";
import { VariantProps } from "@stitches/react";
import { styled } from "../../styles";

const BaseGridContainer = styled("div", {
  columnGap: "$2",
  display: "grid",
  gridTemplateColumns: "repeat(12, 1fr)",
  maxWidth: "100%",
  rowGap: "$2",

  variants: {
    centered: {
      true: {
        marginLeft: "auto",
        marginRight: "auto",
      },
    },
  },
});

export type GridContainerProps = VariantProps<typeof BaseGridContainer> & {
  centered?: boolean;
  width?: number | string;
  css?: any;
};

export const GridContainer = ({
  children,
  width = undefined,
  centered,
  css,
  ...rest
}: PropsWithChildren<GridContainerProps>) => {
  return (
    <BaseGridContainer
      css={{
        width,
        ...css,
      }}
      centered={centered}
      {...rest} // Ensures that composed styles are properly applied
    >
      {children}
    </BaseGridContainer>
  );
};
