import { PropsWithChildren } from "react";
import { styled } from "../../styles";
import { Body } from "../Typography/Typography";

export type TagRuleType = string | Array<string>;

export type TagRule = {
  success: TagRuleType;
  warning: TagRuleType;
  pending: TagRuleType;
  error: TagRuleType;
};

export type TagProps = {
  rules: TagRule;
  small?: boolean;
  value: string;
};

export const TagText = styled(Body, {
  borderRadius: "8px",
  display: "inline-block",
  maxWidth: "100%",
  overflow: "hidden",
  padding: "$1 $2",
  textOverflow: "ellipsis",
  textTransform: "capitalize",
  whiteSpace: "noWrap",

  "@print": {
    backgroundColor: "#fff !important",
    color: "#000 !important",
    margin: "0",
    padding: "0",
  },

  variants: {
    success: {
      true: {
        backgroundColor: "$success",
        color: "$white",
      },
    },
    pending: {
      true: {
        backgroundColor: "$darkMain",
        color: "$white",
      },
    },
    error: {
      true: {
        backgroundColor: "$accent2Shade4",
        color: "$white",
      },
    },
    warning: {
      true: {
        backgroundColor: "$accent2Main",
      },
    },

    primary: {
      true: {
        backgroundColor: "$primary",
        color: "$white",
      },
    },

    small: {
      true: {
        fontSize: "10px",
        margin: "0",
        padding: "$1",
      },
    },
  },
});

const validateRule = (rule: TagRuleType, value: string) => {
  if (Array.isArray(rule)) {
    return rule.includes(value);
  }

  return rule === value;
};

export const Tag = ({
  rules,
  children,
  small,
  value,
}: PropsWithChildren<TagProps>) => {
  const tagText =
    typeof children === "string" ? children.replaceAll("_", " ") : children;

  return (
    <div>
      <TagText
        small={small}
        warning={validateRule(rules.warning, value)}
        success={validateRule(rules.success, value)}
        pending={validateRule(rules.pending, value)}
        error={validateRule(rules.error, value)}
      >
        {tagText}
      </TagText>
    </div>
  );
};
