import { PaginatedResponse } from "@eleo/core";
import { GridContainer } from "../Layout/GridContainer";
import { GridItem } from "../Layout/GridItem";
import Button from "../Button/Button";
import { useState } from "react";
import { styled } from "../../styles";

export type PaginationProps = {
  data?: PaginatedResponse<any>;
  handlePageChange: (page: number) => void;
};

export const usePagination = (defaultPageNumber?: number) => {
  const initialNumber =
    typeof defaultPageNumber !== "undefined" ? defaultPageNumber : 1;
  const [page, setPage] = useState<number>(initialNumber);

  const handlePageChange = (value: number) => {
    setPage(value);
  };

  return { page, handlePageChange };
};

const extractPageNumbers = (url: string) => {
  const queryParams = new URLSearchParams(url.split("?")[1]);
  const page = queryParams.get("page");

  return page ? parseInt(page) : 1;
};

export const Pagination = ({ data, handlePageChange }: PaginationProps) => {
  const { next = null, previous = null } = data || {};

  const onPageChange = (url: string) => {
    const page = extractPageNumbers(url);
    handlePageChange(page);
  };

  return (
    <GridContainer>
      <GridItem
        css={{
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        {previous && (
          <Button
            size="small"
            inline
            onClick={(event) => {
              event.preventDefault();
              onPageChange(previous);
            }}
          >
            Previous
          </Button>
        )}

        {next && (
          <Button
            size="small"
            inline
            type={"button"}
            onClick={(event) => {
              event.preventDefault();
              onPageChange(next);
            }}
          >
            Next
          </Button>
        )}
      </GridItem>
    </GridContainer>
  );
};

export type PaginationWithNumbersProps = {
  handlePageChange: (page: number) => void;
  currentPage: number;
  totalPages: number;
  totalItems: number;
};

export type PaginationButtonProps = {
  currentPage: number;
  page: number;
  handlePageChange: (page: number) => void;
};

const PaginationButton = ({
  currentPage,
  page,
  handlePageChange,
}: PaginationButtonProps) => {
  return (
    <Button
      key={page}
      size="small"
      design={page === currentPage ? undefined : "textOnly"}
      inline
      onClick={(event) => {
        event.preventDefault();
        handlePageChange(page);
      }}
    >
      {page + 1}
    </Button>
  );
};

const EllipsisText = styled("span", {
  display: "inline-flex",
  padding: "0 0.5rem",
  marginLeft: "unset",
  textAlign: "center",
});

export const PaginationWithNumbers = ({
  handlePageChange,
  currentPage,
  totalPages,
}: // totalItems,
PaginationWithNumbersProps) => {
  const MAX_PAGES = 9;

  const halfMax = Math.floor(MAX_PAGES / 2);
  let lowerLimit, upperLimit;

  if (currentPage <= halfMax) {
    lowerLimit = 0;
    upperLimit = Math.min(MAX_PAGES, totalPages);
  } else if (currentPage + halfMax >= totalPages) {
    lowerLimit = totalPages - MAX_PAGES;
    upperLimit = totalPages;
  } else {
    lowerLimit = currentPage - halfMax;
    upperLimit = currentPage + halfMax;
  }

  const pages = Array.from(Array(totalPages).keys());
  const visiblePages = pages.slice(lowerLimit, upperLimit);

  const showFinalNumber = totalPages > upperLimit;

  return (
    <GridContainer>
      <GridItem
        css={{
          alignItems: "center",
          display: "flex",
          justifyContent: "center",
          flexWrap: "wrap",
        }}
      >
        {lowerLimit > 0 && (
          <>
            <PaginationButton
              currentPage={currentPage}
              page={0} // assuming your pages start from 0
              handlePageChange={handlePageChange}
            />

            <EllipsisText>...</EllipsisText>
          </>
        )}

        {visiblePages.map((page) => {
          return (
            <PaginationButton
              currentPage={currentPage}
              key={page}
              page={page}
              handlePageChange={handlePageChange}
            />
          );
        })}

        {showFinalNumber && (
          <>
            <EllipsisText>...</EllipsisText>

            <PaginationButton
              currentPage={currentPage}
              page={totalPages - 1}
              handlePageChange={handlePageChange}
            />
          </>
        )}
      </GridItem>
    </GridContainer>
  );
};
