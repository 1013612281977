import { useEffect, useState } from "react";

// Define DeviceType as an enum
export enum DeviceType {
  Mobile = "mobile",
  Tablet = "tablet",
  Desktop = "desktop",
}

// Custom hook for detecting device type
const useDeviceDetect = (): DeviceType => {
  // Initialize state with the default value based on the browser's current width
  const getInitialDevice = (): DeviceType => {
    if (typeof window === "undefined") return DeviceType.Desktop;

    const width = window.innerWidth;
    if (width < 768) return DeviceType.Mobile;
    else if (width >= 768 && width < 992) return DeviceType.Tablet;
    else return DeviceType.Desktop;
  };

  const [device, setDevice] = useState<DeviceType>(getInitialDevice);

  const handleResize = () => {
    const width = window.innerWidth;
    if (width < 768) {
      setDevice(DeviceType.Mobile);
    } else if (width >= 768 && width < 992) {
      setDevice(DeviceType.Tablet);
    } else {
      setDevice(DeviceType.Desktop);
    }
  };

  useEffect(() => {
    // Add event listener on mount
    window.addEventListener("resize", handleResize);

    // Call handler right away so state gets updated with initial window size
    handleResize();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures effect is only run on mount and unmount

  return device;
};

export default useDeviceDetect;
