import * as SeparatorPrimitive from "@radix-ui/react-separator";
import { styled } from "../../styles";

export const Divider = styled(SeparatorPrimitive.Root, {
  backgroundColor: "$darkShade2",
  display: "flex",

  "&[data-orientation=horizontal]": {
    height: 1,
    width: "100%",
  },

  "&[data-orientation=vertical]": {
    height: "100%",
    width: 1,
  },
});
